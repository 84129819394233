import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  > div + div {
    margin-left: 40px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Maray Customer Story - Rotaready"
          description="Find out how the integration between Rotaready and Tevalis helped Maray improve data accuracy and gain greater visibility over costs."
          url="customer-stories/hospitality-maray"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Maray"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Maray logo"
                  />

                  <GatsbyImage
                    image={data.logoTevalis.childImageSharp.gatsbyImageData}
                    alt="Tevalis logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Find out how the integration between Rotaready and Tevalis helped Maray improve data accuracy and gain greater visibility over costs." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="4" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="55" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="500%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="7 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Maray" />
              </Content>

              <Content>
                <TextBlock text="Maray opened its first 36 cover restaurant on Bold St in Liverpool city centre in June 2014. Since then the Middle East inspired restaurant has opened a 74 cover site in the suburbs of Allerton, and a 65 cover site on the Royal Albert Dock." />
              </Content>

              <Content>
                <TextBlock text="The restaurant group is passionate about delivering authentic, exceptional experiences to its guests on a daily basis. Part of making this happen is ensuring they have the right systems in place." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Maray restaurant frontage and plate of food"
              />
            </Section>

            <Section>
              <Content>
                <TextBlock text="As the business grew, Maray's founders decided an EPOS solution was required to help them scale the business. But they weren't just looking for any old till system, the perfect partner needed to help them achieve maximum efficiency, especially during peak hours of service. They also wanted a centralised way of managing menus and inventory across all sites, plus greater insight on business performance." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.headingEpos.childImageSharp.gatsbyImageData}
                alt="More than just point-of-sale"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Maray and Tevalis" />
              </Content>

              <Content>
                <TextBlock text="After reviewing a number of providers, Maray turned to Tevalis who met every criteria. In order to really understand which Tevalis solution would be the best fit, Tevalis paid a visit to one of their restaurants to learn more about how Maray operates." />
              </Content>

              <Content>
                <Blockquote
                  text="The team at Tevalis were really on it. They made sure they took the time to understand our business and recommend the best solutions for us. The whole journey, from pre-sale to implementation, was very thorough and very smooth!"
                  attribution="James Bates, Co-Founder"
                />
              </Content>

              <Content>
                <TextBlock text="Maray opted to implement the Tevalis Handheld Ordering solution to both increase productivity, and make life easier for its servers by reducing the number of trips they had to make to the till. The restaurant group also opted for three of Tevalis' enterprise solutions - Centralised Management, Stock Management and Business Analytics. A combination of all of these has both increased efficiency for Maray and given its owners greater visibility and control over business performance." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Kitchen and chefs at Maray Dockside restaurant"
              />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Prior to implementing Centralised Management, each restaurant had its own database which meant any menu updates had to be repeated three times. It's certainly saved us a lot of time and effort!"
                  attribution="James Bates, Co-Founder"
                />
              </Content>

              <Content>
                <Blockquote
                  text="By helping us keep a close eye on stock counts, Stock Management has directly impacted our profitability. It's a great security tool!"
                  attribution="James Bates, Co-Founder"
                />
              </Content>

              <Content>
                <TextBlock text="Alongside implementing Tevalis, Maray had also rolled out a workforce management solution to help with building staff rotas and monitoring time and attendance. But as time passed cracks started to show. The existing solution was difficult to use, customer service was lacking and integrations slow to implement." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.headingRota.childImageSharp.gatsbyImageData}
                alt="A refreshing new rota partner"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Maray & Rotaready" />
              </Content>

              <Content>
                <TextBlock text="So in May 2020, Maray set about its search for a new workforce management solution. One that was functionally superior to its existing solution, easier to use, and from a provider who offered great support. Upon a recommendation from one of their industry peers, Maray turned to Rotaready who ticked all the boxes." />
              </Content>

              <Content>
                <TextBlock text="As had been the case with Tevalis, Rotaready worked closely with Maray in order to understand the businesses requirements and ensure a smooth deployment across the restaurants and the workforce." />
              </Content>

              <Content>
                <Blockquote
                  text="We've had a great relationship with the Rotaready team right from the get go. They've always been readily available to help us and seem genuinely enthusiastic about working with us. I'd really struggle to fault the level of service we've had."
                  attribution="James Bates, Co-Founder"
                />
              </Content>

              <Content>
                <TextBlock text="Maray made the decision to use all of Rotaready's features including Rota Scheduling, Time & Attendance and HR, which has given the team at Maray greater visibility and control over wage spend and revenue. This, in turn, has helped Maray's general managers build more informed staff rotas, which are easier than ever for head office to review and approve. They're also enjoying an integration between Rotaready and their payroll provider, which ensures seamless pay runs every week." />
              </Content>

              <Content>
                <Blockquote
                  text="We all really enjoy using Rotaready. The setup was very quick and pain free and the functionality is great - it does everything we need it to. The team find it very easy to use and the service is fantastic - would certainly recommend and indeed have been!"
                  attribution="James Bates, Co-Founder"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="All in all, rolling out Rotaready has helped Maray better deploy over 7 hours a week and resulted in an ROI of 500%." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="I love that nothing is more than a couple of clicks away. Nothing is hidden. Unlike with our old system, it's nice not to have to click through layer after layer to find what I need."
                  attribution="James Bates, Co-Founder"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.headingIntegration.childImageSharp.gatsbyImageData}
                alt="A game changing integration"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Maray, Rotaready & Tevalis" />
              </Content>
            </Section>

            <Section>
              <PopQuote text="Not long after getting started with its new staff scheduling solution, Maray decided to switch on the free integration between Rotaready and Tevalis." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Setting up the integration between Rotaready and Tevalis was so easy. A couple of emails were exchanged and it was done!"
                  attribution="James Bates, Co-Founder"
                />
              </Content>

              <Content>
                <TextBlock text="Having this integration enabled means hourly sales figures are pulled directly from Tevalis into Rotaready. Thanks to Tevalis and Rotaready's modern APIs, this happens in real-time, rather than once per day or overnight." />
              </Content>

              <Content>
                <TextBlock text="Sales figures then appear in Rotaready's cost control and all other popular tools and reports, displayed by revenue category such as food vs drink. Live wage costs are shown alongside and split out in the same way, making it easy to compare revenue and wages across different parts of the business." />
              </Content>

              <Content>
                <TextBlock text="With all the most important metrics in one place, Maray are able to analyse labour percentages, monitor labour across all restaurants and make better business decisions, driving improved commercial performance." />
              </Content>

              <Content>
                <Blockquote
                  text="The integration between Rotaready and Tevalis has been a great benefit to us, particularly when it comes to accuracy of data and visibility over our costs. Rotaready's cost control is my first port of call every single day."
                  attribution="James Bates, Co-Founder"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_maray"
                label="rotaready.com/customer-stories/hospitality-maray"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Maray.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/maray/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/maray/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    logoTevalis: file(relativePath: { eq: "customer-stories/maray/logo-tevalis.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/maray/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/maray/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    headingIntegration: file(relativePath: { eq: "customer-stories/maray/heading-game-changing-integration.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    headingRota: file(relativePath: { eq: "customer-stories/maray/heading-refreshing-new-rota-partner.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    headingEpos: file(relativePath: { eq: "customer-stories/maray/heading-more-than-point-of-sale.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
